
import {
	Message
} from 'element-ui'


import {
	handleAddPlatformCertificateConfig,
	handleQueryPlatformCertificate,
	handleDeletePlatformCertificateConfifById,
	handleSaveCertificateConfigRow,
	handleGenerCertificateByMatchId
} from '@manage/api/bm/certmanage'

export default {
	name: 'platformCert',
	components: {},
	data() {
		return {
			form: { isFontWeight: '1',color:"rgba(0, 0, 0, 1)" ,align:"center",showAuthFlag:"1"},
			tableData: [],
			platformCertDialogVisible: false,
			certImg: "",
		}
	},
	watch: {

	},
	created() {
		this.pageQuery();

	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		pageQuery() {
			handleQueryPlatformCertificate({}).then(res => {
				if (res.status == 200) {
					var tempArr = res.data;
					tempArr.forEach(elem => {
						elem.color = "rgba(" + elem.color + ")";

					});
					this.tableData = tempArr;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
					this.queryCert();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},


		openDialog() { //打开修改对话框			
			this.platformCertDialogVisible = true;
			this.form = {isFontWeight: "1",color:"rgba(0, 0, 0, 1)",align:"center",showAuthFlag:"1"};
		},
		submitInfo() { //保存信息		
			var requestParam = { ...this.form };
			var color = requestParam.color.replaceAll("rgba", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "");
			requestParam.color = color;
			handleAddPlatformCertificateConfig(requestParam).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.platformCertDialogVisible = false;
					this.pageQuery();
					this.form = {isFontWeight: '1',color:"rgba(0, 0, 0, 1)",align:"center",showAuthFlag:"1"};
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		closeDilogEvent() {
			this.platformCertDialogVisible = false;
			this.form = { isFontWeight: '1',color:"rgba(0, 0, 0, 1)",align:"center" ,showAuthFlag:"1"};
		},
	
		queryCert() {
			handleGenerCertificateByMatchId().then(res => {
				this.certImg = res.data + "?r=" + Math.random();
			})
		},
		editRow(id) {
			//row.editFlag = '1';
			if (this.tableData && this.tableData.length > 0) {
				this.tableData.forEach(elem => {
					if (elem.id == id) {
						elem.editFlag = '1';
					} else {
						elem.editFlag = '0';
					}
				});
			}
		},
		saveRow(row) {
			var color = row.color.replaceAll("rgba", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "");
			row.color = color;
			handleSaveCertificateConfigRow(row).then(res => {
				this.pageQuery();
				//	this.queryCert();
			});
		},
		// selectChange(row, color) {	
		// 	console.log(">>>>>"+JSON.stringify(color))
		// 	color = color.replaceAll("rgba", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "");
		// 	row.color = color;
		// 	handleSaveCertificateConfigRow(row).then(res => {
		// 		this.pageQuery();
		// 	});
		// }

	}
}